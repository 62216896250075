import { useRef } from 'react'
import { Sentry } from '@beatgig/helpers/sentry'
import { ServerError } from '@beatgig/api-services'
import useSWR, { responseInterface, keyInterface, cache } from 'swr'
import type { GetInterface } from './use-get.types'
import useStable from '@beatgig/design/hooks/use-stable'

type fetcherFn<Data> = (...args: any) => Data | Promise<Data>

export default function useGet<Data = any>(
  key: keyInterface,
  fn: fetcherFn<Data>,
  config: GetInterface<Data> = {}
): responseInterface<Data | null, ServerError> {
  const noCacheRef = useRef<Date | null>(null)
  if (noCacheRef.current === null) {
    noCacheRef.current = new Date()
  }
  const noCache = config?.cachePolicy === 'no-cache'

  const finalConfig = { ...config }

  if (noCache && config.refreshInterval == null) {
    finalConfig.refreshInterval = 0
  }
  const cacheFirst = useStable(config.cachePolicy === 'cache-first')
  const swr = useSWR(
    () => {
      let finalKey = key
      if (typeof key === 'function') {
        finalKey = key()
      }
      if (!finalKey) return null
      if (!Array.isArray(finalKey)) {
        finalKey = [finalKey]
      }
      if (noCache) {
        finalKey = [...finalKey, noCacheRef]
      }

      return finalKey
    },
    async (...key) => {
      let result: Data
      if (cache.has(key) && cacheFirst.current) {
        return cache.get(key)
      }
      try {
        result = await fn(...key)
      } catch (e) {
        Sentry.captureEvent({
          message: `GET error. Key: ${key.map(JSON.stringify).join(', ')}`,
          extra: {
            error: e,
          },
        })
        throw e
      }
      return result
    },
    finalConfig
  )
  return swr
}
