import { User } from '@beatgig/api/user'
import useGet from '@beatgig/api-hooks/src/use-get'
import { GetUser } from '@beatgig/api-services/user'
import useAuth from './use-auth'
import { ConfigInterface } from 'swr'
import { Platform } from 'react-native'
import { Sentry } from '@beatgig/helpers/sentry'
import Analytics from '@beatgig/analytics/typez'
import { UseGetConfig } from '@beatgig/api-hooks/src/use-get.types'

// dont call identify() more than necessary
let identifiedUid = ''

const useMyAccount = (config?: UseGetConfig<typeof User.me>) => {
  // let me: UserAccount | null = null

  const auth = useAuth()

  const uid = auth?.user?.uid

  const {
    error,
    data,
    isValidating,
    mutate,
    revalidate,
  } = useGet<GetUser | null>(
    () => (uid ? User.swrKey(uid) : null),
    async (uid: string) => {
      console.log('[will-get-user]', {
        // token: await firebase.auth().currentUser?.getIdToken(false),
        uid,
        // openAPIToken:
        //   typeof OpenAPI.TOKEN === 'function'
        //     ? await OpenAPI.TOKEN?.()
        //     : OpenAPI.TOKEN,
      })

      const user = await User.me()

      if (user && User.hasOnboarded(user) && identifiedUid !== uid) {
        identifiedUid = uid

        User.addToAsyncStorage(user)
        Sentry.setUser({
          id: uid,
          name: user.name ?? '',
          phone_number: user.phone_number ?? '',
          email: user.email ?? '',
        })
        Analytics.identify({
          uid,
          name: user.name ?? '',
          phone_number: user.phone_number ?? '',
          email: user.email ?? '',
          user_role: user.user_role,
          user_type: user.metadata.user_type,
        })

        if (Platform.OS === 'web' && process.env.NODE_ENV === 'production') {
          import('logrocket')
            .then((rocket) => rocket.default)
            .then((LogRocket) => {
              LogRocket.identify(uid, {
                name: user.name,
                email: user.email,
                phone_number: user.phone_number ?? '',
                user_role: user.user_role,
                user_type: user.metadata.user_type,
              })
            })
        }
      }
      return user
    },
    config
  )

  return {
    error,
    data,
    isValidating,
    mutate,
    revalidate,
    isLoading: !data && !error && isValidating,
  }
}

export default useMyAccount
