import type { ServerError } from '@beatgig/api-services'
import { Platform } from 'react-native'

function show() {
  console.log('[intercom] might show')
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    console.log('[intercom] will show')
    window.Intercom?.('show')
  }
}

function showNewMessage(prepopulatedContent?: string) {
  console.log('[intercom] might show')
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    console.log('[intercom] will show')
    window.Intercom?.('showNewMessage', prepopulatedContent)
  }
}

function showNewError(e?: ServerError | Error) {
  let err = (e as any)?.body?.message ?? (e as any)?.body?.detail ?? e?.message
  err = err ? JSON.stringify(err) : ''

  showNewMessage(
    `I just got an error. Could you give me a hand?${
      err
        ? `


Here's the error: ${err}`
        : ''
    }`
  )
}

export default function useIntercom() {
  return {
    show,
    showNewMessage,
    showNewError,
  }
}
